<template>
  <h1 class="_heading-1 mb-2" v-t="'login_page.forgot_password'" />
  <h2 class="_heading-3 mb-5" v-t="'login_page.restore_msg'" />
  <form @submit.prevent="handleSubmit()">
    <label class="p-field mb-4">
      <span class="p-label" v-t="'email'"></span>
      <InputText
        type="email"
        v-model="email"
      />
    </label>


    <Button :disabled="support.isLoading" type="submit" class="_full-width ">
      <span v-t="'login_page.restore_password'" />
    </Button>
    <p class="mt-4 _text-center">
      <span v-t="'login_page.remembered'"> </span>
      <router-link :to="{ name: 'Login' }" v-t="'login_page.sign_in'" class="_link _link-secondary _text-uppercase _text-bold ml-2" />
    </p>
  </form>
</template>

<script>
import cookies from 'js-cookie'
import { useRouter } from 'vue-router'
import { reactive, ref } from 'vue';
import { useStore } from 'vuex'

import InputText from 'primevue/inputtext';
import Button from 'primevue/button';

async function postData(url = '', data = {}) {
  const response = await fetch(url, {
    method: 'POST',
    mode: 'cors',
    cache: 'no-cache',
    credentials: 'same-origin',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(data)
  });
  return await response.json();
}

export default {
  components: {
    InputText,
    Button,
  },
  setup() {
    const store = useStore()
    const router = useRouter()

    const email = ref();
    const support = reactive({
      isLoading: false,
    });

    const handleSubmit = () => {
      support.isLoading = true
      postData('https://caiman.inflowcompany.com/api/auth/login', { email })
      .then((data) => {
        support.isLoading = false
        // { expires: data.expires_in / 8.64e+7 }
        cookies.set('token', data.access_token)
        cookies.set('user', JSON.stringify(data.user))
        cookies.set('no-refresh', true)
        store.commit('account/setAccount', data.user)
        router.push({ path: '/' })
      })
      .catch((error) => {
        support.isLoading = false
        console.log('error', error);
      });
    }

    return {
      email,
      handleSubmit,
      support
    }
  }
}
</script>